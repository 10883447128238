// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles/Header.css */
.mobileVisible {
    display: none;
}

.desktopVisible {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 50px;
    background-color: #ffffff;
}

.logo {
    height: 50px;
}

@media (max-width: 768px) {
    .desktopVisible {
        display: none;
    }
    
    .mobileVisible {
        display: block;
        width: 100%;
        padding: 10px 20px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }
    .logo {
        height: 80px; /* Smaller logo on mobile devices */
      
      }
    
}
.menu {
    width: 75%; /* El menú ocupará el 75% del ancho */
    float: right; /* Posiciona el menú a la derecha */
    /* Otras propiedades de estilo que puedas necesitar */
}`, "",{"version":3,"sources":["webpack://./src/components/styles/Header.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,cAAc;QACd,WAAW;QACX,kBAAkB;QAClB,yCAAyC;IAC7C;IACA;QACI,YAAY,EAAE,mCAAmC;;MAEnD;;AAEN;AACA;IACI,UAAU,EAAE,qCAAqC;IACjD,YAAY,EAAE,mCAAmC;IACjD,qDAAqD;AACzD","sourcesContent":["/* styles/Header.css */\n.mobileVisible {\n    display: none;\n}\n\n.desktopVisible {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px 50px;\n    background-color: #ffffff;\n}\n\n.logo {\n    height: 50px;\n}\n\n@media (max-width: 768px) {\n    .desktopVisible {\n        display: none;\n    }\n    \n    .mobileVisible {\n        display: block;\n        width: 100%;\n        padding: 10px 20px;\n        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);\n    }\n    .logo {\n        height: 80px; /* Smaller logo on mobile devices */\n      \n      }\n    \n}\n.menu {\n    width: 75%; /* El menú ocupará el 75% del ancho */\n    float: right; /* Posiciona el menú a la derecha */\n    /* Otras propiedades de estilo que puedas necesitar */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
