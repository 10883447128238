// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles/HamburgerMenu.css */
.menu-button {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1000; /* Ensure it's above other elements */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; /* Set the width to make it a square */
    height: 40px; /* Set the height to make it a square */
    padding: 0; /* Remove any padding */
    border-radius: 5px; /* Optional: Adjust the border-radius */
    background-color: #4CAF50; /* Optional: Set the background color */
}

.ant-drawer-header {
    background-color: #f0f2f5;
}

.ant-drawer-title {
    font-size: 1.5em;
    font-weight: bold;
}

.ant-drawer-body {
    padding: 0;
}

.ant-menu-vertical {
    border-right: none;
}

.ant-menu-item {
    font-size: 1.2em;
    padding: 10px 20px;
}

.ant-btn-primary {
    width: 100%;
    margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/HamburgerMenu.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;IACI,eAAe;IACf,SAAS;IACT,WAAW;IACX,aAAa,EAAE,qCAAqC;IACpD,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW,EAAE,sCAAsC;IACnD,YAAY,EAAE,uCAAuC;IACrD,UAAU,EAAE,uBAAuB;IACnC,kBAAkB,EAAE,uCAAuC;IAC3D,yBAAyB,EAAE,uCAAuC;AACtE;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB","sourcesContent":["/* styles/HamburgerMenu.css */\n.menu-button {\n    position: fixed;\n    top: 10px;\n    right: 10px;\n    z-index: 1000; /* Ensure it's above other elements */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 40px; /* Set the width to make it a square */\n    height: 40px; /* Set the height to make it a square */\n    padding: 0; /* Remove any padding */\n    border-radius: 5px; /* Optional: Adjust the border-radius */\n    background-color: #4CAF50; /* Optional: Set the background color */\n}\n\n.ant-drawer-header {\n    background-color: #f0f2f5;\n}\n\n.ant-drawer-title {\n    font-size: 1.5em;\n    font-weight: bold;\n}\n\n.ant-drawer-body {\n    padding: 0;\n}\n\n.ant-menu-vertical {\n    border-right: none;\n}\n\n.ant-menu-item {\n    font-size: 1.2em;\n    padding: 10px 20px;\n}\n\n.ant-btn-primary {\n    width: 100%;\n    margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
